const contracts = {
  mainnet: {
    interest: '',
    market: '',
    liquidation: '',
    overseer: '',
    custody: '',
    collector: '',
    solid: '',
    capa: '',
    gov: ''
  },
  testnet: {
    interest:
      'terra1m3wk4u86h6md4r4qem5fg8ulux2csav0tgd5jv0zc9q9yldjfl5qzd6frh',
    market: 'terra14e9cdkfpgzcysgeg3rhdjmtjch2h0tqt7htcft6d8ph2a5gkhacst9flzr',
    liquidation:
      'terra1erpd5j6msncwn9pm0uqlh7rwu4cxedmqtnf6s2nd98t3rdt37uvqjez5pt',
    overseer:
      'terra132945g90t4qdeajwmlmsda0uzqyn7jcjaw64h56tsl95a6s7gu5qnptuyq',
    custody: 'terra1c36fqu8zcehu8uf59hp4cc3phar4gh8jt569jm538ztr74nyyaeqjqxkjd',
    collector: 'terra1e5t3w5whenvr5ajqcgsvrqf0hvr68ehek7eh8jjspf0g6au0kvxs4d8w58',
    solid: 'terra1gg6n5pevqsxr56j27gw9p7kcy4sm3sjch26tsqwqekypqd4cr7vsjhl5u3',
    capa: 'terra17pgd4kcs50n6tjha48tuzr9d2md6gz30pc49dcv33mt2xr4yz9hq7nuw97',
    gov: 'terra1x9mauww03krhxzcft2dnrf79pee00wnwh4vvsqjjkljll2shv98qdc7mnn'
  },
}

export default contracts
