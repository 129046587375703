import { useWallet, WalletStatus } from '@terra-money/wallet-provider'
import { useSetRecoilState } from 'recoil'
import STATE from '../../const/recoil'

import Button from '../button/Button'
import './ConnectButton.css'

export default function ConnectButton() {
  const { status, wallets, disconnect } = useWallet()
  const setPopup = useSetRecoilState(STATE.popup)

  return (
    <div className='connect'>
      {status !== WalletStatus.WALLET_CONNECTED ? (
        <Button
          onClick={() => {
            setPopup(true)
          }}
        >
          <i className='bx bxs-wallet'></i> Connect
        </Button>
      ) : (
        <Button
          onClick={() => {
            disconnect()
          }}
        >
          <i className='bx bxs-wallet'></i> terra1...
          {wallets[0].terraAddress.substring(
            wallets[0].terraAddress.length - 5,
          )}
        </Button>
      )}
    </div>
  )
}
