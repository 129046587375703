import { useEffect, useState } from 'react'
import {
  useLCDClient,
  useWallet,
  WalletStatus,
} from '@terra-money/wallet-provider'
import contracts from '../../const/contracts'

import Popup from 'reactjs-popup'
import { MsgExecuteContract } from '@terra-money/terra.js'
import { TxModal } from '../../components/txModal/TxModal'
import { useRecoilValue } from 'recoil'
import STATE from '../../const/recoil'

export default function ProvidedCollateral({
  name,
  icon,
  token,
}: {
  name: string
  icon: string
  token: string
}) {
  const lcd = useLCDClient()
  const updater = useRecoilValue(STATE.updater)
  const [balance, setBalance] = useState<number>(0)
  const [spendable, setSpendable] = useState<number>(0)
  const [amount, setAmount] = useState<number>(0)
  const { status, wallets, network } = useWallet()

  useEffect(() => {
    setAmount(0)
    ;(async () => {
      if (status !== WalletStatus.WALLET_CONNECTED) {
        setBalance(0)
      } else {
        const response = (await lcd.wasm.contractQuery(
          contracts[network.name as 'mainnet' | 'testnet'].custody,
          {
            borrower: {
              address: wallets[0].terraAddress,
            },
          },
        )) as { balance: string; spendable: string }

        setBalance(parseInt(response.balance) / 1_000_000)
        setSpendable(parseInt(response.spendable) / 1_000_000)
      }
    })()
  }, [token, status, wallets, lcd.wasm, network, updater])

  return (
    <tr>
      <td className='borrow__asset'>
        <div className='borrow__table__flex'>
          <img src={icon} alt={name} className='borrow__asset__icon' />
          {name}
        </div>
      </td>
      <td className='borrow__asset__balance'>{balance}</td>
      <td className='borrow__actions'>
        <div className='borrow__table__flex'>
          <Popup
            trigger={<button className='borrow__button'>Withdraw</button>}
            modal
            nested
          >
            {
              // @ts-expect-error
              (close) => (
                <div className='modal__background'>
                  <div className='modal'>
                    <button
                      onClick={() => {
                        setAmount(0)
                        close()
                      }}
                      className='modal__close'
                    >
                      <i className='bx bx-x'></i>
                    </button>

                    <TxModal title={`Withdraw ${name}`}>
                      {(broadcast, walletAddress) => (
                        <>
                          <div className='modal__input'>
                            <input
                              type='number'
                              value={amount}
                              onChange={(e) => {
                                setAmount(parseFloat(e.target.value))
                              }}
                              min='0'
                              max={(balance - spendable).toFixed(6)}
                            />
                            <button
                              onClick={() =>
                                setAmount(
                                  Math.round(
                                    (balance - spendable) * 1_000_000,
                                  ) / 1_000_000,
                                )
                              }
                            >
                              Max
                            </button>
                          </div>

                          <button
                            disabled={!walletAddress}
                            onClick={() => {
                              broadcast([
                                new MsgExecuteContract(
                                  walletAddress || '',
                                  contracts[
                                    network.name as 'mainnet' | 'testnet'
                                  ].overseer,
                                  {
                                    unlock_collateral: {
                                      collaterals: [
                                        [
                                          token,
                                          `${Math.floor(amount * 1_000_000)}`,
                                        ],
                                      ],
                                    },
                                  },
                                ),
                                new MsgExecuteContract(
                                  walletAddress || '',
                                  contracts[
                                    network.name as 'mainnet' | 'testnet'
                                  ].custody,
                                  {
                                    withdraw_collateral: {
                                      amount: `${Math.floor(
                                        amount * 1_000_000,
                                      )}`,
                                    },
                                  },
                                ),
                              ])
                            }}
                            className='modal__button'
                          >
                            Confirm
                          </button>
                        </>
                      )}
                    </TxModal>
                  </div>
                </div>
              )
            }
          </Popup>
        </div>
      </td>
    </tr>
  )
}
