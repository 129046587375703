import { useRecoilValue } from 'recoil'
import STATE from '../../../const/recoil'
import './Result.css'

type ResultType =
  | {
      success: true
      txhash: string
    }
  | {
      success: false
      message: string
    }

export default function Result(result: ResultType) {
  const network = useRecoilValue(STATE.network)
  return (
    <>
      <h3>{result.success ? 'Success' : 'Error'}</h3>
      {result.success ? (
        <i className='bx bxs-check-circle result__img'></i>
      ) : (
        <i className='bx bxs-x-circle result__img'></i>
      )}
      {result.success ? (
        <p className='result__hash'>
          Tx hash:{' '}
        <a
          href={`https://finder.terra.money/${network}/tx/${result.txhash}`}
          target='_blank'
          rel='noreferrer'
        >
          {result.txhash.substring(0,8)}...{result.txhash.substring(result.txhash.length - 8)}
        </a>
        </p>

      ) : (
        <p className='result__error'>{result.message}</p>
      )}
    </>
  )
}
