import React from 'react'
import ReactDOM from 'react-dom/client'
import { RecoilRoot } from 'recoil'
import { BrowserRouter } from 'react-router-dom'
import { WalletProvider, getChainOptions } from '@terra-money/wallet-provider'

import './index.css'
import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
getChainOptions().then((chainOptions) => {
  root.render(
    <React.StrictMode>
      <WalletProvider {...chainOptions}>
        <RecoilRoot>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </RecoilRoot>
      </WalletProvider>
    </React.StrictMode>,
  )
})
