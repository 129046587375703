import './Loader.css'
import loader from './loader.svg'

export default function Loader() {
  return (
    <>
      <h3>Loading</h3>
      <img src={loader} alt='loading' className='loader__img'/>
      <p className='loader__txt'>Your transaction is being broadcasted, it may take a few seconds...</p>
    </>
  )
}
