import { MsgExecuteContract } from '@terra-money/terra.js'
import { useWallet } from '@terra-money/wallet-provider'
import { useState } from 'react'
import Popup from 'reactjs-popup'
import { TxModal } from '../../components/txModal/TxModal'
import contracts from '../../const/contracts'
import { ProposalType } from './type'

function PropStatus({ status }: { status: ProposalType['status'] }) {
  switch (status) {
    case 'in_progress':
      return <span style={{ color: '#FF00FF' }}>In Progress</span>
    case 'passed':
      return <span style={{ color: '#7EE787' }}>Passed</span>
    case 'rejected':
      return <span style={{ color: '#FF7B72' }}>Rejected</span>
    case 'failed':
      return <span style={{ color: '#FF7B72' }}>Failed</span>
    case 'executed':
      return <span style={{ color: '#7EE787' }}>Executed</span>
    case 'expired':
      return <span style={{ color: '#FFA656' }}>Expired</span>
  }
}

export default function Proposal({
  proposal,
  balance,
}: {
  proposal: ProposalType
  balance: number
}) {
  const [vote, setVote] = useState<'yes' | 'no' | undefined>()
  const { network } = useWallet()

  const yesPerCent =
    parseInt(proposal.yes_votes) / parseInt(proposal.staked_amount) || 0

  const noPerCent =
    parseInt(proposal.no_votes) / parseInt(proposal.staked_amount) || 0

  return (
    <section className='gov__prop'>
      <p className='gov__prop__id'>
        ID: {proposal.id} - <PropStatus status={proposal.status} />
      </p>
      <h4>{proposal.title}</h4>
      <a href={proposal.link} target='_blank' rel='noreferrer'>
        {proposal.link}
      </a>
      <p>{proposal.description}</p>

      <div className='gov__prop__votes'>
        <div
          className='gov__prop__votes__chart'
          style={{
            background: `linear-gradient(90deg, #7EE787 ${
              yesPerCent * 100
            }%, #FF7B72 ${yesPerCent * 100}%,  #FF7B72 ${
              (yesPerCent + noPerCent) * 100
            }%, #161616 ${(yesPerCent + noPerCent) * 100}%)`,
          }}
        />
        <div className='gov__prop__votes__data'>
          <span>
            Votes: <b>{((yesPerCent + noPerCent) * 100).toFixed(1)} %</b>
          </span>
          <div>
            <span style={{ color: '#7EE787' }}>
              Yes: <b>{(yesPerCent * 100).toFixed(1)} %</b>
            </span>
            <span style={{ color: '#FF7B72' }}>
              No: <b>{(noPerCent * 100).toFixed(1)} %</b>
            </span>
          </div>
        </div>
      </div>


      {proposal.status === 'in_progress' && (<>
        <Popup
          trigger={<button className='borrow__button'>Vote</button>}
          modal
          nested
        >
          {
            // @ts-expect-error
            (close) => (
              <div className='modal__background'>
                <div className='modal'>
                  <button
                    onClick={() => {
                      setVote(undefined)
                      close()
                    }}
                    className='modal__close'
                  >
                    <i className='bx bx-x'></i>
                  </button>

                  <TxModal title={`Vote prop #${proposal.id}`}>
                    {(broadcast, walletAddress) => (
                      <>
                        <div className='vote__options'>
                          <button
                            className={
                              'vote__option__yes ' +
                              (vote === 'yes'
                                ? 'vote__option__yes--active'
                                : '')
                            }
                            onClick={() => setVote('yes')}
                          >
                            Yes
                          </button>
                          <button
                            className={
                              'vote__option__no ' +
                              (vote === 'no' ? 'vote__option__no--active' : '')
                            }
                            onClick={() => setVote('no')}
                          >
                            No
                          </button>
                        </div>
                        <button
                          disabled={!walletAddress && !vote}
                          onClick={() =>
                            broadcast([
                              new MsgExecuteContract(
                                walletAddress || '',
                                contracts[
                                  network.name as 'mainnet' | 'testnet'
                                ].gov,
                                {
                                  cast_vote: {
                                    poll_id: proposal.id,
                                    vote,
                                    amount: (balance * 1_000_000).toFixed(0),
                                  },
                                },
                              ),
                            ])
                          }
                          className='modal__button'
                        >
                          Confirm
                        </button>
                      </>
                    )}
                  </TxModal>
                </div>
              </div>
            )
          }
        </Popup>
        {
          // TODO: height to time
        }
        <p className='prop__endtime'>Vote ends at height: <span>{proposal.end_height}</span></p>
        </>
      )}
    </section>
  )
}
