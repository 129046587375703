import { atom } from 'recoil'

const popup = atom<boolean>({
  key: 'popup',
  default: false,
})

const network = atom<'mainnet' | 'testnet'>({
  key: 'network',
  default: 'testnet',
})

const updater = atom<number>({
  key: 'updater',
  default: 0,
})

export default {
  popup,
  network,
  updater,
}