import { useEffect, useState } from 'react'
import axios from 'axios'

import './Home.css'
import { useLCDClient, useWallet, WalletStatus } from '@terra-money/wallet-provider'
import { useRecoilValue } from 'recoil'
import STATE from '../../const/recoil'

interface AssetType {
  name: string
  token: string
  icon: string
}

function Asset( asset: AssetType ) {
  const { status, wallets } = useWallet()
  const lcd = useLCDClient()
  const updater = useRecoilValue(STATE.updater)
  const [balance, setBalance] = useState<number>(0)

  useEffect(() => {
    ;(async () => {
      if (status !== WalletStatus.WALLET_CONNECTED) {
        setBalance(0)
      } else {
        const response = (await lcd.wasm.contractQuery(asset.token, {
          balance: {
            address: wallets[0].terraAddress,
          },
        })) as { balance: string }
        setBalance(parseInt(response.balance)/1_000_000)
      }
    })()
  }, [status, wallets, lcd.wasm, asset.token, updater])
  
  return (
    <tr>
      <td className='home__asset'>
        <div className='home__table__flex'>
          <img src={asset.icon} alt={asset.name} className='home__asset__icon' />
          {asset.name}
        </div>
      </td>
      <td className='home__asset__balance'>
        {balance}
      </td>
      <td className='home_actions'>
          <button className='home__button'>Send</button>
      </td>
    </tr>
  )
}

export default function Home() {
  const { network } = useWallet()
  const [assets, setAssets] = useState<AssetType[]>([])

  useEffect(() => {
    (async () => {
      const response = await axios.get('https://assets.capapult.finance/coins.json')
      setAssets(response.data[network.name])
    })()
  }, [network])
  return (
    <>
      <h2 className='section__title'>Home</h2>

      <section className='home'>
        <h3>Coins</h3>
        <table>
          <thead>
            <tr>
              <th>Asset</th>
              <th>Balance</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {assets.map((asset) => <Asset {...asset} />)}
          </tbody>
        </table>
      </section>
      <div className='section__bottom'></div>
    </>
  )
}
