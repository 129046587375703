import Button from '../../components/button/Button'
import './Airdrop.css'

export default function Airdrop() {
  return (
    <>
      <h2 className='section__title'>Airdrop</h2>

      <section className='airdrop'>
        <p className='airdrop__amount'>
          <span>Amount</span>
          <span>5.5 CAPA</span>
        </p>
        <p className='airdrop__fee'>
          <span>Tx Fee</span>
          <span>0.05 Luna</span>
        </p>

        <Button onClick={() => {}} className='airdrop__button'>
          Claim
        </Button>
      </section>
      <div className='section__bottom'></div>
    </>
  )
}
