import { MsgExecuteContract } from '@terra-money/terra.js'
import {
  useLCDClient,
  useWallet,
  WalletStatus,
} from '@terra-money/wallet-provider'
import { useEffect, useState } from 'react'
import Popup from 'reactjs-popup'
import { useRecoilValue } from 'recoil'
import STATE from '../../const/recoil'
import { TxModal } from '../../components/txModal/TxModal'
import contracts from '../../const/contracts'
import './Governance.css'
import Proposal from './Proposal'
import { ProposalType } from './type'

export default function Governance() {
  const updater = useRecoilValue(STATE.updater)
  const [amount, setAmount] = useState<number>(0)
  const [prop, setProp] = useState({ title: '', description: '', link: '' })
  const [balance, setBalance] = useState<number>(0)
  const [staked, setStaked] = useState<number>(0)
  const [locked, setLocked] = useState<number>(0)
  const [proposals, setProposals] = useState<ProposalType[]>([])
  const lcd = useLCDClient()
  const { status, wallets, network } = useWallet()

  useEffect(() => {
    setAmount(0)
    ;(async () => {
      if (status !== WalletStatus.WALLET_CONNECTED) {
        setBalance(0)
      } else {
        const capaBalPromise = lcd.wasm.contractQuery(
          contracts[network.name as 'mainnet' | 'testnet'].capa,
          {
            balance: {
              address: wallets[0].terraAddress,
            },
          },
        ) as Promise<{ balance: string }>

        const stakerPromise = lcd.wasm.contractQuery(
          contracts[network.name as 'mainnet' | 'testnet'].gov,
          {
            staker: {
              address: wallets[0].terraAddress,
            },
          },
        ) as Promise<{
          balance: string
          locked_balance: {
            balance: string
          }[][]
        }>

        const proposalsPromise = lcd.wasm.contractQuery(
          contracts[network.name as 'mainnet' | 'testnet'].gov,
          {
            polls: {
              filter: 'in_progress',
              start_after: 0,
              limit: 10,
              order_by: 'asc',
            },
          },
        ) as Promise<{
          polls: ProposalType[]
        }>

        const [capaBal, staker, proposalsResult] = await Promise.all([
          capaBalPromise,
          stakerPromise,
          proposalsPromise,
        ])

        setBalance(parseInt(capaBal.balance) / 1_000_000)
        setStaked(parseInt(staker.balance) / 1_000_000)
        setLocked(
          Math.max(
            ...staker.locked_balance.map(el => parseInt(el[1].balance)),
          ) / 1_000_000,
        )
        setProposals(proposalsResult.polls)
      }
    })()
  }, [status, wallets, lcd.wasm, network, updater])

  return (
    <>
      <h2 className='section__title'>Governance</h2>

      <section className='gov'>
        <h3>Staking</h3>
        <div className='gov__capa'>
          <img src='https://assets.capapult.finance/icon/Capa.png' alt='CAPA' />
          <h4>Capapult (CAPA)</h4>
          <p>
            <span>APR</span> 10.3%
          </p>
        </div>

        <div className='gov__balance'>
          <div>
            <h4>Available:</h4>
            <p>{balance} CAPA</p>
            <Popup
              trigger={<button className='borrow__button'>Stake</button>}
              modal
              nested
            >
              {
                // @ts-expect-error
                (close) => (
                  <div className='modal__background'>
                    <div className='modal'>
                      <button
                        onClick={() => {
                          setAmount(0)
                          close()
                        }}
                        className='modal__close'
                      >
                        <i className='bx bx-x'></i>
                      </button>

                      <TxModal title='Gov Stake'>
                        {(broadcast, walletAddress) => (
                          <>
                            <div className='modal__input'>
                              <input
                                type='number'
                                value={amount}
                                onChange={(e) => {
                                  setAmount(parseFloat(e.target.value))
                                }}
                                min='0'
                                max={
                                  Math.floor(balance * 1_000_000) / 1_000_000
                                }
                              />
                              <button
                                onClick={() =>
                                  setAmount(
                                    Math.floor(balance * 1_000_000) / 1_000_000,
                                  )
                                }
                              >
                                Max
                              </button>
                            </div>

                            <button
                              disabled={!walletAddress}
                              onClick={() =>
                                broadcast([
                                  new MsgExecuteContract(
                                    walletAddress || '',
                                    contracts[
                                      network.name as 'mainnet' | 'testnet'
                                    ].capa,
                                    {
                                      send: {
                                        contract:
                                          contracts[
                                            network.name as
                                              | 'mainnet'
                                              | 'testnet'
                                          ].gov,
                                        amount: (amount * 1_000_000).toFixed(0),
                                        msg: 'eyJzdGFrZV92b3RpbmdfdG9rZW5zIjoge319',
                                      },
                                    },
                                  ),
                                ])
                              }
                              className='modal__button'
                            >
                              Confirm
                            </button>
                          </>
                        )}
                      </TxModal>
                    </div>
                  </div>
                )
              }
            </Popup>
          </div>

          <div>
            <h4>Staked:</h4>
            <p>{staked} CAPA</p>
            <Popup
              trigger={<button className='borrow__button'>Unstake</button>}
              modal
              nested
            >
              {
                // @ts-expect-error
                (close) => (
                  <div className='modal__background'>
                    <div className='modal'>
                      <button
                        onClick={() => {
                          setAmount(0)
                          close()
                        }}
                        className='modal__close'
                      >
                        <i className='bx bx-x'></i>
                      </button>

                      <TxModal title='Gov Unstake'>
                        {(broadcast, walletAddress) => (
                          <>
                            <div className='modal__input'>
                              <input
                                type='number'
                                value={amount}
                                onChange={(e) => {
                                  setAmount(parseFloat(e.target.value))
                                }}
                                min='0'
                                max={
                                  Math.floor((staked - locked) * 1_000_000) /
                                  1_000_000
                                }
                              />
                              <button
                                onClick={() =>
                                  setAmount(
                                    Math.floor((staked - locked) * 1_000_000) /
                                      1_000_000,
                                  )
                                }
                              >
                                Max
                              </button>
                            </div>

                            <button
                              disabled={!walletAddress}
                              onClick={() =>
                                broadcast([
                                  new MsgExecuteContract(
                                    walletAddress || '',
                                    contracts[
                                      network.name as 'mainnet' | 'testnet'
                                    ].gov,
                                    {
                                      withdraw_voting_tokens: {
                                        amount: (amount * 1_000_000).toFixed(0),
                                      },
                                    },
                                  ),
                                ])
                              }
                              className='modal__button'
                            >
                              Confirm
                            </button>
                          </>
                        )}
                      </TxModal>
                    </div>
                  </div>
                )
              }
            </Popup>
          </div>
        </div>
      </section>

      <div className='gov__title'>
        <h2 className='section__title'>Proposals</h2>
        <Popup
          trigger={<button className='borrow__button'>Create</button>}
          modal
          nested
        >
          {
            // @ts-expect-error
            (close) => (
              <div className='modal__background'>
                <div className='modal'>
                  <button
                    onClick={() => {
                      setProp({ title: '', description: '', link: '' })
                      close()
                    }}
                    className='modal__close'
                  >
                    <i className='bx bx-x'></i>
                  </button>

                  <TxModal title='Create Proposal'>
                    {(broadcast, walletAddress) => (
                      <>
                        <p className='prop__field__title'>Title:</p>
                        <div className='modal__input prop__input'>
                          <input
                            type='text'
                            value={prop.title}
                            onChange={(e) => {
                              setProp({ ...prop, title: e.target.value })
                            }}
                          />
                        </div>

                        <p className='prop__field__title'>Link:</p>
                        <div className='modal__input prop__input'>
                          <input
                            type='url'
                            value={prop.link}
                            onChange={(e) => {
                              setProp({ ...prop, link: e.target.value })
                            }}
                          />
                        </div>

                        <p className='prop__field__title'>Description:</p>
                        <div className='modal__input prop__input'>
                          <textarea
                            value={prop.description}
                            onChange={(e) => {
                              setProp({ ...prop, description: e.target.value })
                            }}
                          />
                        </div>

                        <p className='prop__field__title'>Deposit:</p>
                        <div className='modal__input prop__input'>
                          <input type='number' value='100' disabled />
                          <p className='prop__deposit'>
                            <img
                              src='https://assets.capapult.finance/icon/Capa.png'
                              alt='CAPA'
                            />
                            CAPA
                          </p>
                        </div>

                        <button
                          disabled={!walletAddress}
                          onClick={() =>
                            broadcast([
                              new MsgExecuteContract(
                                walletAddress || '',
                                contracts[
                                  network.name as 'mainnet' | 'testnet'
                                ].capa,
                                {
                                  send: {
                                    contract:
                                      contracts[
                                        network.name as 'mainnet' | 'testnet'
                                      ].gov,
                                    amount: '100000000',
                                    msg: Buffer.from(
                                      JSON.stringify({
                                        create_poll: {
                                          title: prop.title,
                                          description: prop.description,
                                          link: prop.link,
                                          execute_msgs: [],
                                        },
                                      }),
                                    ).toString('base64'),
                                  },
                                },
                              ),
                            ])
                          }
                          className='modal__button'
                        >
                          Create
                        </button>
                      </>
                    )}
                  </TxModal>
                </div>
              </div>
            )
          }
        </Popup>
      </div>

      {!proposals.length && (
        <section className='gov__empty'>
          <i className='bx bx-ghost'></i>
          <h4>There is no proposal yet.</h4>
        </section>
      )}

      {proposals
        .sort((a, b) => b.id - a.id)
        .map((proposal) => (
          <Proposal key={proposal.id} proposal={proposal} balance={staked} />
        ))}

      <div className='section__bottom'></div>
    </>
  )
}
