import {
  useLCDClient,
  useWallet,
  WalletStatus,
} from '@terra-money/wallet-provider'
import { useEffect, useState } from 'react'
import contracts from '../../const/contracts'
import Popup from 'reactjs-popup'
import { MsgExecuteContract } from '@terra-money/terra.js'
import { TxModal } from '../../components/txModal/TxModal'
import { useRecoilValue } from 'recoil'
import STATE from '../../const/recoil'
interface PositionData {
  borrowLimit: number
  borrowed: number
  apr: number
  balance: number
}

export function BorrowPosition() {
  const lcd = useLCDClient()
  const updater = useRecoilValue(STATE.updater)
  const { status, wallets, network } = useWallet()
  const [data, setData] = useState<PositionData>({
    borrowLimit: 0,
    borrowed: 0,
    balance: 0,
    apr: 0,
  })

  const [amount, setAmount] = useState<number>(0)

  useEffect(() => {
    ;(async () => {
      if (status !== WalletStatus.WALLET_CONNECTED) {
        setData({ borrowLimit: 0, borrowed: 0, apr: 0, balance: 0 })
      } else {
        const limitPromise = lcd.wasm.contractQuery(
          contracts[network.name as 'mainnet' | 'testnet'].overseer,
          {
            borrow_limit: {
              borrower: wallets[0].terraAddress,
            },
          },
        ) as Promise<{
          borrower: string
          borrow_limit: string
        }>

        const borrowInfoPromise = lcd.wasm.contractQuery(
          contracts[network.name as 'mainnet' | 'testnet'].market,
          {
            borrower_info: {
              borrower: wallets[0].terraAddress,
            },
          },
        ) as Promise<{
          borrower: string
          interest_index: string
          loan_amount: string
        }>

        const balancePromise = lcd.wasm.contractQuery(
          contracts[network.name as 'mainnet' | 'testnet'].solid,
          {
            balance: {
              address: wallets[0].terraAddress,
            },
          },
        ) as Promise<{ balance: string }>

        const [limit, borrowInfo] = await Promise.all([
          limitPromise,
          borrowInfoPromise,
        ])

        setData({
          borrowLimit: parseInt(limit.borrow_limit) / 1_000_000,
          borrowed: parseInt(borrowInfo.loan_amount) / 1_000_000,
          apr: parseFloat(borrowInfo.interest_index),
          balance: parseInt((await balancePromise).balance) / 1_000_000,
        })
      }
    })()
  }, [status, lcd, network, wallets, updater])

  return (
    <>
      <h3>Your position</h3>
      <div className='borrow__container'>
        <h4>Borrow Limit</h4>
        <p className='borrow__limt'>
          {data.borrowLimit}
          <img
            src='https://assets.capapult.finance/icon/Solid.png'
            alt='Solid'
            className='borrow__limit__icon'
          />
        </p>

        <h4>Borrowed</h4>
        <p className='borrow__limt'>
          {data.borrowed}
          <img
            src='https://assets.capapult.finance/icon/Solid.png'
            alt='Solid'
            className='borrow__limit__icon'
          />
        </p>

        <h4>Net APR</h4>
        <p className='borrow__limt'>{(-data.apr).toFixed(2)} %</p>

        <Popup
          trigger={<button className='borrow__button'>Borrow</button>}
          modal
          nested
        >
          {
            // @ts-expect-error
            (close) => (
              <div className='modal__background'>
                <div className='modal'>
                  <button
                    onClick={() => {
                      setAmount(0)
                      close()
                    }}
                    className='modal__close'
                  >
                    <i className='bx bx-x'></i>
                  </button>

                  <TxModal title='Borrow'>
                    {(broadcast, walletAddress) => (
                      <>
                        <div className='borrow__modal__value'>
                          <p className='borrow__amount'>
                            {amount / 1_000_000}{' '}
                            <img
                              src='https://assets.capapult.finance/icon/Solid.png'
                              alt='Solid'
                              className='borrow__amount__icon'
                            />
                          </p>

                          <input
                            type='range'
                            min='0'
                            max={(data.borrowLimit - data.borrowed) * 1_000_000}
                            value={amount}
                            onChange={(e) =>
                              setAmount(parseInt(e.target.value))
                            }
                            className='slider'
                            style={{
                              background: `linear-gradient(90deg, #FC0CFF ${
                                (amount /
                                  ((data.borrowLimit - data.borrowed) *
                                    1_000_000)) *
                                100
                              }%, #161616 ${
                                (amount /
                                  ((data.borrowLimit - data.borrowed) *
                                    1_000_000)) *
                                100
                              }%)`,
                            }}
                          />
                        </div>

                        <button
                          disabled={!walletAddress}
                          onClick={() =>
                            broadcast([
                              new MsgExecuteContract(
                                walletAddress || '',
                                contracts[
                                  network.name as 'mainnet' | 'testnet'
                                ].market,
                                {
                                  borrow_stable: { borrow_amount: `${amount}` },
                                },
                              ),
                            ])
                          }
                          className='modal__button'
                        >
                          Confirm
                        </button>
                      </>
                    )}
                  </TxModal>
                </div>
              </div>
            )
          }
        </Popup>

        <Popup
          trigger={<button className='borrow__button'>Repay</button>}
          modal
          nested
        >
          {
            // @ts-expect-error
            (close) => (
              <div className='modal__background'>
                <div className='modal'>
                  <button
                    onClick={() => {
                      setAmount(0)
                      close()
                    }}
                    className='modal__close'
                  >
                    <i className='bx bx-x'></i>
                  </button>

                  <TxModal title='Repay'>
                    {(broadcast, walletAddress) => (
                      <>
                        <div className='borrow__modal__value'>
                          <p className='borrow__amount'>
                            {amount / 1_000_000}{' '}
                            <img
                              src='https://assets.capapult.finance/icon/Solid.png'
                              alt='Solid'
                              className='borrow__amount__icon'
                            />
                          </p>

                          <input
                            type='range'
                            min='0'
                            max={Math.min(data.borrowed, data.balance) * 1_000_000}
                            value={amount}
                            onChange={(e) =>
                              setAmount(parseInt(e.target.value))
                            }
                            className='slider'
                            style={{
                              background: `linear-gradient(90deg, #FC0CFF ${
                                (amount / (Math.min(data.borrowed, data.balance)  * 1_000_000)) * 100
                              }%, #161616 ${
                                (amount / (Math.min(data.borrowed, data.balance)  * 1_000_000)) * 100
                              }%)`,
                            }}
                          />
                        </div>

                        <button
                          disabled={!walletAddress}
                          onClick={() =>
                            broadcast([
                              new MsgExecuteContract(
                                walletAddress || '',
                                contracts[
                                  network.name as 'mainnet' | 'testnet'
                                ].solid,
                                {
                                  send: {
                                    contract:
                                      contracts[
                                        network.name as 'mainnet' | 'testnet'
                                      ].market,
                                    amount: `${amount}`,
                                    msg: 'eyJyZXBheV9zdGFibGUiOnt9fQ==',
                                  },
                                },
                              ),
                            ])
                          }
                          className='modal__button'
                        >
                          Confirm
                        </button>
                      </>
                    )}
                  </TxModal>
                </div>
              </div>
            )
          }
        </Popup>
      </div>
    </>
  )
}
