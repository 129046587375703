import { Msg } from '@terra-money/terra.js'
import { useConnectedWallet, useLCDClient } from '@terra-money/wallet-provider'
import { useState } from 'react'
import { useRecoilState } from 'recoil'
import STATE from '../../const/recoil'
import Loader from './loader/Loader'
import Result from './result/Result'

type Status =
  | {
      status: 'none' | 'loading'
    }
  | {
      status: 'error'
      message: string
    }
  | {
      status: 'success'
      txhash: string
    }

export function TxModal({
  title,
  children,
}: {
  title: string
  children: (
    broadcast: (msgs: Msg[]) => void,
    wallet: string | undefined,
  ) => React.ReactNode
}) {
  const [state, setState] = useState<Status>({ status: 'none' })
  const [updater, setUpdater] = useRecoilState(STATE.updater)
  const connectedWallet = useConnectedWallet()
  const lcd = useLCDClient()

  function broadcastTx(msgs: Msg[]) {
    if (!connectedWallet) return

    setState({ status: 'loading' })

    connectedWallet
      .post({
        msgs,
        // fee: new Fee(500000, { uluna: (500000 * .15).toFixed(0) }),
      })
      .then(async (result) => {
        if (result.success) {
          for (let i = 0; i < 45; i++) {
            // query txhash
            const data = await lcd.tx
              .txInfo(result.result.txhash)
              .catch(() => {})

            // if hash is onchain return data
            if (data) {
              data.code
                ? setState({
                    status: 'error',
                    message: data.raw_log,
                  })
                : setState({
                    status: 'success',
                    txhash: data.txhash,
                  })
              return
            }

            // else wait 250ms and then repeat
            await new Promise((resolve) => setTimeout(resolve, 1_000))
          }
          setState({
            status: 'error',
            message: 'Timeout waiting for tx confirmation',
          })
        } else {
          setState({
            status: 'error',
            message: result.result.raw_log,
          })
        }
      })
      .catch((e) => {
        console.error(e)
        setState({
          status: 'error',
          message: e,
        })
      })
      .finally(() => {
        setUpdater(updater + 1)
      })
  }

  switch (state.status) {
    case 'loading':
      return <Loader />
    case 'error':
      return <Result success={false} message={state.message.toString()} />
    case 'success':
      return <Result success={true} txhash={state.txhash} />
    case 'none':
      return (
        <div>
          <h3>{title}</h3>
          {children(broadcastTx, connectedWallet?.walletAddress)}
        </div>
      )
  }
}
