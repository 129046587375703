import { Route, Routes } from 'react-router-dom'

import ConnectButton from './components/connect/ConnectButton'
import Header from './components/header/Header'
import MobileLogo from './components/mobile/MobileLogo'
import WalletPopup from './components/wallet/WalletPopup'
import Airdrop from './pages/airdrop/Airdrop'
import Borrow from './pages/borrow/Borrow'
import Governance from './pages/gov/Governance'
import Home from './pages/home/Home'

function App() {
  return (
    <div className='app'>
      <Header />

      <main>
        <MobileLogo />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/borrow' element={<Borrow />} />
          <Route path='/airdrop' element={<Airdrop />} />
          <Route path='/gov' element={<Governance />} />
        </Routes>
      </main>

      <ConnectButton />
      <WalletPopup />
    </div>
  )
}

export default App
