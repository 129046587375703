import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import axios from 'axios'
import STATE from '../../const/recoil'

import './Borrow.css'
import AvailableCollateral from './AvailableCollateral'
import {
  useLCDClient,
  useWallet,
  WalletStatus,
} from '@terra-money/wallet-provider'
import contracts from '../../const/contracts'
import ProvidedCollateral from './ProvidedCollateral'
import { BorrowPosition } from './BorrowPosition'

interface CollateralType {
  name: string
  icon: string
  token: string
  stake: string
  provided: boolean
}

export default function Airdrop() {
  const network = useRecoilValue(STATE.network)
  const updater = useRecoilValue(STATE.updater)
  const lcd = useLCDClient()
  const { status, wallets } = useWallet()

  const [collaterals, setCollaterals] = useState<CollateralType[]>([])

  useEffect(() => {
    ;(async () => {
      const result = await axios.get(
        'https://assets.capapult.finance/collaterals.json',
      )
      const collaterals = result.data[network].map(
        async (collateral: CollateralType) => {
          if (status === WalletStatus.WALLET_CONNECTED) {
            const result = (await lcd.wasm.contractQuery(
              contracts[network].custody,
              {
                borrower: {
                  address: wallets[0].terraAddress,
                },
              },
            )) as { balance: string }

            return { ...collateral, provided: parseInt(result.balance) > 0 }
          } else {
            return { ...collateral, provided: false }
          }
        },
      )

      setCollaterals(await Promise.all(collaterals))
    })()
  }, [network, status, wallets, lcd.wasm, updater])
  return (
    <>
      <h2 className='section__title'>Borrow</h2>

      <section className='borrow'>
        <BorrowPosition />
      </section>

      {collaterals.filter((c) => c.provided).length > 0 && (
        <section className='borrow'>
          <h3>Your collaterals</h3>
          <table>
            <thead>
              <tr>
                <th>Asset</th>
                <th>Provided</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {collaterals
                .filter((c) => c.provided)
                .map((c) => (
                  <ProvidedCollateral {...c} key={c.token} />
                ))}
            </tbody>
          </table>
        </section>
      )}

      <section className='borrow'>
        <h3>Available collaterals</h3>
        <table>
          <thead>
            <tr>
              <th>Asset</th>
              <th>Balance</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {collaterals.map((c) => (
              <AvailableCollateral {...c} key={c.token} />
            ))}
          </tbody>
        </table>
      </section>
      <div className='section__bottom'></div>
    </>
  )
}
