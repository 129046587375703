import { useEffect, useState } from 'react'
import {
  useLCDClient,
  useWallet,
  WalletStatus,
} from '@terra-money/wallet-provider'
import contracts from '../../const/contracts'

import Popup from 'reactjs-popup'
import { MsgExecuteContract } from '@terra-money/terra.js'
import { TxModal } from '../../components/txModal/TxModal'
import { useRecoilValue } from 'recoil'
import STATE from '../../const/recoil'

export default function AvailableCollateral({
  name,
  icon,
  token,
  stake,
}: {
  name: string
  icon: string
  token: string
  stake: string
}) {
  const lcd = useLCDClient()
  const updater = useRecoilValue(STATE.updater)
  const [balance, setBalance] = useState<number>(0)
  const [exRate, setExrate] = useState<number>(0)
  const [amount, setAmount] = useState<number>(0)
  const { status, wallets, network } = useWallet()

  useEffect(() => {
    setAmount(0)
    ;(async () => {
      if (status !== WalletStatus.WALLET_CONNECTED) {
        setBalance(0)
      } else {
        const stateResponse = (await lcd.wasm.contractQuery(stake, {
          state: {},
        })) as {
          state: {
            exchange_rate: string
          }
        }
        const response = (await lcd.wasm.contractQuery(token, {
          balance: { address: wallets[0].terraAddress },
        })) as { balance: string }

        setExrate(parseFloat(stateResponse.state.exchange_rate))
        setBalance(parseInt(response.balance) / 1_000_000)
      }
    })()
  }, [token, status, wallets, lcd.wasm, stake, updater])

  return (
    <tr>
      <td className='borrow__asset'>
        <div className='borrow__table__flex'>
          <img src={icon} alt={name} className='borrow__asset__icon' />
          {name}
        </div>
      </td>
      <td className='borrow__asset__balance'>
        {(balance * exRate).toFixed(6)}
      </td>
      <td className='borrow__actions'>
        <div className='borrow__table__flex'>
          <Popup
            trigger={<button className='borrow__button'>Provide</button>}
            modal
            nested
          >
            {
              // @ts-expect-error
              (close) => (
                <div className='modal__background'>
                  <div className='modal'>
                    <button
                      onClick={() => {
                        setAmount(0)
                        close()
                      }}
                      className='modal__close'
                    >
                      <i className='bx bx-x'></i>
                    </button>

                    <TxModal title={`Provide ${name}`}>
                      {(broadcast, walletAddress) => (
                        <>
                          <div className='modal__input'>
                            <input
                              type='number'
                              value={amount}
                              onChange={(e) => {
                                setAmount(parseFloat(e.target.value))
                              }}
                              min='0'
                              max={
                                Math.floor(balance * exRate * 1_000_000) /
                                1_000_000
                              }
                            />
                            <button
                              onClick={() =>
                                setAmount(
                                  Math.floor(balance * exRate * 1_000_000) /
                                    1_000_000,
                                )
                              }
                            >
                              Max
                            </button>
                          </div>

                          <button
                            disabled={!walletAddress}
                            onClick={async () => {
                              // re-calculate exchange rate just before sending tx
                              const stateResponse =
                                (await lcd.wasm.contractQuery(stake, {
                                  state: {},
                                })) as {
                                  state: {
                                    exchange_rate: string
                                  }
                                }

                              broadcast([
                                new MsgExecuteContract(
                                  walletAddress || '',
                                  token,
                                  {
                                    send: {
                                      contract:
                                        contracts[
                                          network.name as 'mainnet' | 'testnet'
                                        ].custody,
                                      amount: `${Math.ceil(
                                        (amount /
                                          parseFloat(
                                            stateResponse.state.exchange_rate,
                                          )) *
                                          1_000_000,
                                      )}`,
                                      msg: 'eyJkZXBvc2l0X2NvbGxhdGVyYWwiOnt9fQ==',
                                    },
                                  },
                                ),
                                new MsgExecuteContract(
                                  walletAddress || '',
                                  contracts[
                                    network.name as 'mainnet' | 'testnet'
                                  ].overseer,
                                  {
                                    lock_collateral: {
                                      collaterals: [
                                        [token, `${amount * 1_000_000}`],
                                      ],
                                    },
                                  },
                                ),
                              ])
                            }}
                            className='modal__button'
                          >
                            Confirm
                          </button>
                        </>
                      )}
                    </TxModal>
                  </div>
                </div>
              )
            }
          </Popup>
        </div>
      </td>
    </tr>
  )
}
