import { Link, useLocation } from 'react-router-dom'

import logo from '../../img/logo.svg'
import icon from '../../img/icon.svg'
import './Header.css'

export default function Header() {
  const { pathname } = useLocation()

  return (
    <header>
      <div>
        <a href='https://capapult.finance' target='blank'>
          <img src={logo} alt='Capapult' className='logo__large' />
          <img src={icon} alt='Capapult' className='logo__small' />
        </a>
        <nav className='link__container'>
          <Link
            to='/'
            className={pathname === '/' ? 'link link__active' : 'link'}
          >
            <i className='bx bxs-home'></i> Home
          </Link>
          <Link
            to='/borrow'
            className={
              pathname.startsWith('/borrow') ? 'link link__active' : 'link'
            }
          >
            <i className='bx bxs-coin'></i> Borrow
          </Link>
          <Link
            to='/gov'
            className={
              pathname.startsWith('/gov') ? 'link link__active' : 'link'
            }
          >
            <i className='bx bxs-pie-chart-alt-2'></i> Governance
          </Link>
          <Link
            to='/airdrop'
            className={
              pathname.startsWith('/airdrop') ? 'link link__active' : 'link'
            }
          >
            <i className='bx bxs-gift'></i> Airdrop
          </Link>
        </nav>
      </div>

      <a href='https://twitter.com/capapult' target='blank' className='email'>
        <i className='bx bxl-twitter'></i> @capapult
      </a>
    </header>
  )
}
