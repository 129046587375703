import './MobileLogo.css'
import logo from '../../img/logo.svg'

export default function MobileLogo() {
    return (
        <a href='https://capapult.finance' target='blank'>
          <img
            src={logo}
            alt='Capapult'
            className='logo__mobile'
          />
        </a>
      )
}