import './Button.css'

export default function Button({
  children,
  onClick,
  className,
  secondary,
}: {
  children: React.ReactNode | React.ReactNode[]
  onClick?: () => void
  className?: string
  secondary?: boolean
}) {
  return (
    <button
      className={
        secondary ? 'button__secondary ' + className : 'button ' + className
      }
      onClick={onClick}
    >
      {children}
    </button>
  )
}
