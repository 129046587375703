import { useWallet, WalletStatus, ConnectType } from '@terra-money/wallet-provider'
import { useRecoilState } from 'recoil'
import STATE from '../../const/recoil'

import './WalletPopup.css'

export default function WalletPopup() {
  const [popup, setPopup] = useRecoilState(STATE.popup)
  const {
    status,
    availableConnections,
    connect,
  } = useWallet()

  const enabledConnections = availableConnections.filter((c) =>
    ['EXTENSION', 'WALLETCONNECT'].includes(c.type),
  )

  function ConnectButton({
    icon,
    name,
    type,
  }: {
    icon: string
    name: string
    type: ConnectType
  }) {
    return (
      <button
        className='popup__button'
        onClick={() => {
          connect(type)
        }}
      >
        <img src={icon} alt={name} />
        {name}
      </button>
    )
  }
  return popup && status === WalletStatus.WALLET_NOT_CONNECTED ? (
    <div className='popup__bg'>
      <div className='popup'>
        <button
          className='popup__close'
          onClick={() => {
            setPopup(false)
          }}
        >
          <i className='bx bx-x'></i>
        </button>
        <h2>Connect Wallet</h2>
        {enabledConnections.map((c) => (
          <ConnectButton key={c.type} {...c} />
        ))}
      </div>
    </div>
  ) : (
    <></>
  )
}
